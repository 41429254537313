jQuery(function () {
    isAddBlueColor = $("#AddBlueColor").val();
    if (isAddBlueColor == 'Yes') {
        $(".inFocus").not('.DynamicContentSection ').parents("div.section.vspace-sm").addClass("bg_blue");
        $(".inFocus").not('.DynamicContentSection ').addClass("isAddcolor");
    }
    else {
        $(".inFocus").not('.DynamicContentSection ').parents("div.section.vspace-sm").removeClass("bg_blue");
        $(".inFocus").not('.DynamicContentSection ').parents("div.section.vspace-sm").css("background-color", "#F8F9FA");
        $(".inFocus").not('.DynamicContentSection ').removeClass("isAddcolor");
    }

    $(".inContentDynamic--SingleCard").click(function (e) {
        e.preventDefault();
        var $target = $(this);
        var promoHeading = $target.find('.ImageAboveContent--Content').find('h3').text().trim() || 'Default Promo Name';
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "ga4_select_promotion",
            "ga4_data": {
                "creative_name": "Article box",
                "promotion_name": promoHeading
            }
        });
        setTimeout(function () {
            window.location = $target.find("a").attr("href");
        }, 200);

        return false;
    });
        $(".inFocusContent--SingleCard").click(function (e) {
        e.preventDefault();
        var $target = $(this);
        var promoHeading = $target.find('.ImageAboveContent--Content').find('h3').text().trim() || 'Default Promo Name';
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "ga4_select_promotion",
            "ga4_data": {
                "creative_name": "Article box",
                "promotion_name": promoHeading
            }
        });
        setTimeout(function () {
            window.location = $target.find("a").attr("href");
        }, 200);

        return false;
        });

    $('button.Tags__Button, button.tag_Menu_Button').click(function (e) {

    });
    $(document).on("click", ".sm-focus-button", function (e) {
    });
    $('.data-test-display').each(function () {
        $height = $(this).height();
        $(this).css('width', "1110px");
    });

});
//$(window).resize(function () {
//    setTimeout(function () {
//        equal_height_cards();
//    }, 200);
//});
// Function to set equal height for all content divs
function setEqualHeight() {
    if (window.matchMedia("(min-width: 767px)").matches) {
        $('.ImageAboveContent--Content').css('height', 'unset')
        const ContentCards = document.querySelectorAll('.inFocusContent--SingleCard.visible ');
        let maxHeight = 0;

        // Find the maximum height of all content divs
        ContentCards.forEach(card => {
            const content = card.querySelector('.ImageAboveContent--Content');
            const contentHeight = content.offsetHeight;
            if (contentHeight > maxHeight) {
                maxHeight = contentHeight;
            }
        });

        // Set the height of all content divs to the maximum height found
        ContentCards.forEach(card => {
            const content = card.querySelector('.ImageAboveContent--Content');
            content.style.height = `${maxHeight}px`;
        });
    }
}

// Call the function on page load and whenever the window is resized
window.addEventListener('load', setEqualHeight);
$(window).on('resize', function () {
    setTimeout(function () {
        setEqualHeight();
    }, 200);
})
$('.tag_Menu_Button, .Tags__Button').on('click', function () {
    setTimeout(function () {
        setEqualHeight();
    }, 200);
})

//   for enter key press  
document.addEventListener('keydown', function (event) {
    if (event.key === 'Enter') {
        setEqualHeight();
    }
});